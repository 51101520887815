$(function() {
  AOS.init({
    offset: 100,
    duration: 500,
    easing: 'ease-out-quint',
    delay: 0,
    once: true,
  });

  var animationSpeed = 350;
  var stepDuration = 3200;
  var animating = false;

  function loopStepHighlight(){
    var step01;
    var step02;
    var step03;
    step01 = setInterval(function(){
      $('.Step-01 .Highlighted-bg').fadeOut(animationSpeed);
      $('.Step-01').removeClass('Step--highlight');
      $('.Step-02 .Highlighted-bg').fadeIn(animationSpeed);
      $('.Step-02').addClass('Step--highlight');
      clearInterval(step01);
      step02 = setInterval(function(){
        $('.Step-02 .Highlighted-bg').fadeOut(animationSpeed);
        $('.Step-02').removeClass('Step--highlight');
        $('.Step-03').addClass('Step--highlight');
        $('.Step-03 .Highlighted-bg').fadeIn(animationSpeed);
        clearInterval(step02);
        step03 = setInterval(function(){
          $('.Step-03 .Highlighted-bg').fadeOut(animationSpeed);
          $('.Step-03').removeClass('Step--highlight');
          $('.Step-01').addClass('Step--highlight');
          $('.Step-01 .Highlighted-bg').fadeIn(animationSpeed);
          clearInterval(step03);
          loopStepHighlight();
        }, stepDuration)
      }, stepDuration)
    }, stepDuration);

  }

  if($('#how-refis-works').length != 0){
    if(isElementInViewport($('#how-refis-works'))){
      if(!animating){
        loopStepHighlight();
        animating = true;
      }
    }

    $(window).on('scroll', function(){
      if(isElementInViewport($('#how-refis-works'))){
        if(!animating){
          loopStepHighlight();
          animating = true;
        }
      }
    });
  }

  if (!Modernizr.objectfit) {
    $('img.js-object-fit').each(function () {
      var t = $(this),
      src = t.data('lazy') ? t.data('lazy') : t.attr('src'),
      s = "url('" + src + "')",
      p = t.parent(),
      d = $('<div></div>');
      t.hide();
      p.append(d);
      d.css({
        'width': '100%',
        'height': '100%',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'background-image': s,
        'position': 'absolute',
        'top': '0',
        'left': '0',
      });
    });
  }

  $('.alert').alert();

  /**
   * Scroll links
   */
  $('a.js-scrollLink, .js-scrollLink > a').click(function (e) {
    e.preventDefault();

    var firstChar = $(this).attr('href').substring(0, 1);
    if (firstChar != '#') {
      if (!$($(this).attr('href').substring(1)).length) {
        window.location = $(this).attr('href');
      }
      var $target = $($(this).attr('href').substring(1));
    } else {
      var $target = $($(this).attr('href'));
    }

    var $this = $(this);
    var $parent = $this.parents('.js-scrollLinkParent');

    $parent.find('.is-active').each(function() {
      $(this).removeClass('is-active');
    })
    $this.parents('li').addClass('is-active');

    $([document.documentElement, document.body]).animate({
      scrollTop: $target.offset().top
    }, 500);
  })


  /**
   * Header
   */
  $('.scroll-down').on('click', function(e){
    $([document.documentElement, document.body]).animate({
      scrollTop: '450px',
      easing: 'easeout'
    }, 500);
  });

  var $header = $('.js-header');

  $(window).on('scroll', checkHeaderScroll);

  function checkHeaderScroll() {
    if ($(window).scrollTop() >= 32) {
      $header.addClass('is-fixed');
      // $('.MouseWrapper .mouse').fadeOut();
      $('.scroll-down').fadeOut();
    } else {
      $header.removeClass('is-fixed');
    }
  }


  /**
   *
   * Nav
   */
  var $headerNavToggle = $('.js-headerNavToggle');
  var $headerNav = $('.js-headerNav');

  var $body = $('body');

  $headerNavToggle.click(function(e) {
    e.preventDefault();
    var $this = $(this);

    if ($this.hasClass('is-active')) {
      closeNav();
    } else {
      openNav();
    }
  });
  $headerNav.click(function(e) {
    if(!$(e.target).closest('.Header-menu').length) {
      closeNav();
    }
  })

  function openNav() {
    var scrollbarWidth = getScrollbarWidth();

    $body.css('padding-right', scrollbarWidth + 'px');
    $headerNav.css('padding-right', scrollbarWidth + 'px');

    $body.addClass('is-navOpen');
    $headerNavToggle.addClass('is-active');

    $headerNav.fadeIn(250);
    var timeout = window.setTimeout(function() {
      $headerNav.addClass('is-shown')
    }, 50);

  }

  function closeNav() {
    $body.removeClass('is-navOpen');
    $headerNavToggle.removeClass('is-active');

    $body.css('padding-right', '');
    $headerNav.css('padding-right', '');

    $headerNav.fadeOut(250).removeClass('is-shown');
  }

  $(window).resize(function(){

    if ($('.js-header').width() >= 992 ){
      $body.css('padding-right', '');
      $headerNav.css('padding-right', '');
    }
  });


  // Hide Header on on scroll down
  var $cta = $('.js-cta');
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;

  $(window).scroll(function (event) {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      checkCtaScroll();
      didScroll = false;
    }
  }, 250);

  function checkCtaScroll() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
      return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop) {
      // Scroll Down
      // $('header').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $cta.addClass('show');
      }
    }

    lastScrollTop = st;
  }



  /**
   * Range
   */
  var $range = $('.js-range');

  if ($range.length) {
    $range.each(function() {
      var $this = $(this);
      var $target = $($this.data('target'));

      $target.val($this.val());

      $this.on('input', function(e) {
        $target.val(e.target.value);
      })
    })
  }
})

function getScrollbarWidth() {
  return $(window).outerWidth() - $(window).innerWidth();
}

function isElementInViewport(el) {
  //special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  var rect = el.getBoundingClientRect();

  return (
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.bottom >= 0
  );
}
